define("discourse/plugins/discourse-version-tag-priority/select-kit/addon/components/mini-tag-chooser-version", ["exports", "discourse/lib/computed", "discourse-i18n", "select-kit/components/mini-tag-chooser"], function (_exports, _computed, _discourseI18n, _miniTagChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CUSTOME_TAG_TYPE = "version";
  var _default = _exports.default = _miniTagChooser.default.extend({
    maxTagsPerTopic: (0, _computed.setting)("max_version_tags_per_topic"),
    search(filter) {
      const data = {
        q: filter || "",
        limit: this.maxTagSearchResults,
        categoryId: this.selectKit.options.categoryId
      };
      if (this.value) {
        data.selected_tags = this.value.slice(0, 100);
      }
      if (!this.selectKit.options.everyTag) {
        data.filterForInput = true;
      }
      return this.searchTags(`/${CUSTOME_TAG_TYPE}_tags/filter/search`, data, this._transformJson);
    },
    modifyNoSelection() {
      if (this.selectKit.options.minimum > 0) {
        return this.defaultItem(null, _discourseI18n.default.t(`${CUSTOME_TAG_TYPE}_tagging.choose_for_topic_required`, {
          count: this.selectKit.options.minimum
        }));
      } else {
        return this.defaultItem(null, _discourseI18n.default.t(`${CUSTOME_TAG_TYPE}_tagging.choose_for_topic`));
      }
    }
  });
});