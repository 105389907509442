define("discourse/plugins/discourse-version-tag-priority/discourse/initializers/add-type-topic-chooser-composer", ["exports", "@ember/object", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/models/composer", "discourse/models/topic", "discourse-i18n"], function (_exports, _object, _ajaxError, _pluginApi, _composer, _topic, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-version-tag-priority";
  const SAVING = "saving",
    OPEN = "open",
    _update_serializer = {
      raw: "reply",
      topic_id: "topic.id",
      raw_old: "rawOld",
      tags: "combinedTags"
    },
    _edit_topic_serializer = {
      title: "topic.title",
      categoryId: "topic.category.id",
      featuredLink: "topic.featured_link"
    };
  var _default = _exports.default = {
    name: "add-type-topic-chooser-composer",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.modifyClass("model:composer", {
          pluginId: PLUGIN_ID,
          is_private: false,
          version_tags: [],
          combinedTags: (0, _object.computed)("tags", "version_tags", function () {
            return this.get("tags").concat(this.get("version_tags"));
          }),
          filteredNotVersionTags: (0, _object.computed)("topic.tags", function () {
            const versionTagPrefix = this.siteSettings.discourse_version_tag_priority_symbol;
            return this.get("topic.tags").filter(tag => {
              return !tag.startsWith(versionTagPrefix);
            });
          }),
          filteredVersionTags: (0, _object.computed)("topic.tags", function () {
            const versionTagPrefix = this.siteSettings.discourse_version_tag_priority_symbol;
            return this.get("topic.tags").filter(tag => {
              return tag.startsWith(versionTagPrefix);
            });
          }),
          cantSubmitPost: (0, _object.computed)("loading", "canEditTitle", "titleLength", "targetRecipients", "targetRecipientsArray", "replyLength", "categoryId", "missingReplyCharacters", "tags", "topicFirstPost", "minimumRequiredTags", "user.staff", "version_tags", function () {
            // can't submit while loading
            if (this.loading) {
              return true;
            }

            // title is required when
            //  - creating a new topic/private message
            //  - editing the 1st post
            if (this.canEditTitle && !this.titleLengthValid) {
              return true;
            }

            // reply is always required
            if (this.missingReplyCharacters > 0) {
              return true;
            }
            if (this.site.can_tag_topics && !this.isStaffUser && this.topicFirstPost && this.minimumRequiredTags) {
              const tagsArray = this.tags || [];
              if (tagsArray.length < this.minimumRequiredTags) {
                return true;
              }
            }
            if (this.topicFirstPost) {
              // user should modify topic template
              const category = this.category;
              if (category && category.topic_template) {
                if (this.reply.trim() === category.topic_template.trim()) {
                  this.dialog.alert(_discourseI18n.default.t("composer.error.topic_template_not_modified"));
                  return true;
                }
              }
            }
            if (this.privateMessage) {
              // need at least one user when sending a PM
              return this.targetRecipients && this.targetRecipientsArray.length === 0;
            } else {
              // has a category? (when needed)
              return this.requiredCategoryMissing;
            }
          }),
          editPost(opts) {
            const post = this.post;
            const oldCooked = post.cooked;
            let promise = Promise.resolve();

            // Update the topic if we're editing the first post
            if (this.title && post.post_number === 1) {
              const topic = this.topic;
              if (topic.details.can_edit) {
                const topicProps = this.getProperties(Object.keys(_edit_topic_serializer));

                // Override here: add tags = combinedTags to topicProps
                this.serialize(_update_serializer, topicProps);

                // frontend should have featuredLink but backend needs featured_link
                if (topicProps.featuredLink) {
                  topicProps.featured_link = topicProps.featuredLink;
                  delete topicProps.featuredLink;
                }

                // If we're editing a shared draft, keep the original category
                if (this.action === _composer.EDIT_SHARED_DRAFT) {
                  const destinationCategoryId = topicProps.categoryId;
                  promise = promise.then(() => topic.updateDestinationCategory(destinationCategoryId));
                  topicProps.categoryId = topic.get("category.id");
                }
                promise = promise.then(() => _topic.default.update(topic, topicProps));
              } else if (topic.details.can_edit_tags) {
                promise = promise.then(() => topic.updateTags(this.tags));
              }
            }
            const props = {
              edit_reason: opts.editReason,
              image_sizes: opts.imageSizes,
              cooked: this.getCookedHtml()
            };
            this.serialize(_update_serializer, props);
            this.set("composeState", SAVING);
            const rollback = (0, _ajaxError.throwAjaxError)(error => {
              post.setProperties("cooked", oldCooked);
              this.set("composeState", OPEN);
              if (error.jqXHR && error.jqXHR.status === 409) {
                this.set("editConflict", true);
              }
            });
            post.setProperties({
              cooked: props.cooked,
              staged: true
            });
            this.appEvents.trigger("post-stream:refresh", {
              id: post.id
            });
            return promise.then(() => {
              return post.save(props).then(result => {
                this.clearState();
                return result;
              });
            }).catch(rollback).finally(() => {
              post.set("staged", false);
              this.appEvents.trigger("post-stream:refresh", {
                id: post.id
              });
            });
          }
        });

        // Add field version_tags in ajax body with value of this.version_tags when create topic
        _composer.default.serializeOnCreate("tags", "combinedTags");

        // Add field when open composer
        _composer.default.serializeToTopic("tags", "filteredNotVersionTags");
        _composer.default.serializeToTopic("version_tags", "filteredVersionTags");
      });
    }
  };
});